import React from "react";
import WeBookPicture from "../img/WeBookPicture.jpg";
import VideoWeBook from "../video/VideoWeBook";

class ViewWeBook extends React.Component {
    render() {
        return (
        <div class="h-auto grid place-content-center mx-8 my-24 lg:mx-72 lg:my-60">

            <div class="mb-12">  
                <p class=" font-display font-semibold text-3xl text-red  mb-8 tracking-wide text-center lg:text-6xl ">Lediberg WeBook</p>
                <p class="font-body text-sm text-center text-black lg:text-xl lg:mb-12 ">Verbinde die Aktualität der digitalen Welt mit dem haptischen Schreiberlebnis in einem großartigen Marken-Notizbuch</p>
            </div>  

            <div class="justify-center lg:grid lg:gap-0 lg:grid-cols-2 ">

                <div class="lg:flex lg:justify-center">
                    <img src={WeBookPicture} alt="webook_productpicture" class="mb-8 lg:h-96 w-auto" />
                </div>

                <div class=" lg:grid lg:grid-cols-1 lg:place-content-start">

                    <div>
                    <p class="text-justify font-body leading-relaxed lg:leading-relaxed text-sm lg:text-base text-gray mb-4 lg:mb-8">
                        Die Wortzusammenführung von web und book spiegelt die Verbindung von digitaler und analoger Welt, welche bei diesem Notizbuch im Fokus steht, wider.<br/><br/>

                        Mit Hilfe der NFC-Technologie hast du die Möglichkeit, digitale Inhalte bereitzustellen und mit regelmäßigem Content ganz nah an deiner Zielgruppe zu sein.<br/><br/>

                        Das WeBook kommt in einer hochwertigen Geschenkverpackung.
                    </p>
                        <div class="flex justify-center lg:flex-none lg:justify-start">
                        <VideoWeBook />
                        </div>
                    </div>

                </div>

            </div>

        </div>
        );
    };
}

export default ViewWeBook;

