import React from "react";
import MahlwerckPicture from "../img/MahlwerckPicture.jpg";


class ViewMahlwerck extends React.Component {
    render() {
        return (
        <div class="h-auto grid place-content-center mx-8 my-24 lg:mx-72 lg:my-60">

            <div class="mb-12">  
                <p class=" font-display font-semibold text-3xl text-red  mb-8 tracking-wide text-center lg:text-6xl ">Mahlwerck Augmented Reality</p>
                <p class="font-body text-sm text-center text-black lg:text-xl lg:mb-12 ">Ich sehe was, was du nicht siehst - die neue Veredelungsart von Mahlwerck machts möglich!</p>
            </div>  

            <div class="justify-center lg:grid lg:gap-0 lg:grid-cols-2 ">

                <div class="lg:flex lg:justify-center">
                    <img src={MahlwerckPicture} alt="webook_productpicture" class="mb-8 h-96 w-auto " />
                </div>

                <div class=" lg:grid lg:grid-cols-1 lg:place-content-center">

                    <div>
                    <p class="text-justify font-body leading-relaxed lg:leading-relaxed text-sm lg:text-base text-gray mb-4 lg:mb-8">
                    Produkte und Marken werden vielseitiger, greifbarer, erlebbarer, sie erhalten eine neue Dimension. Imagekampagne, Serviceleistung, Hintergrundgeschichte sind nur einige Inspirationen aus unbegrenzten Möglichkeiten.
                    <br/><br/>
                    Mit Hilfe der getbaff-App ist es möglich, den in deinem Motiv unsichtbaren und eingearbeiteten Code zu lesen und deinen Inhalt, ob Foto, Video oder Text, wiederzugeben.

                    </p>
                        
                    </div>

                </div>

            </div>

        </div>
        );
    };
}

export default ViewMahlwerck;

