import React from "react";
import BGPicture from "../img/BGPicture.png";

class Teaser extends React.Component {
    render() {
        return (
        <div class="flex h-screen place-items-center bg-left lg:bg-top bg-local lg:bg-fixed bg-no-repeat bg-cover lg:bg-cover" style={{ backgroundImage: `url(${BGPicture})` }} >
            <div class="bg-fixed w-full ml-8 mr-8 lg:ml-48 lg:mr-48">
                <p class="font-display font-bold text-3xl lg:text-6xl text-center text-red hidden lg:block lg:pt-24">HAPTISCHE WERBUNG - GOES DIGITAL</p>
                <p class="font-display font-bold text-3xl lg:text-6xl text-center text-red lg:hidden">HAPTISCHE WERBUNG GOES DIGITAL</p>
                <p class="text-base lg:text-2xl font-body text-center text-gray mt-6">Full-Service Agentur für haptische Werbeträger</p>
            </div>
        </div>
        );
    };
}

export default Teaser;
