import React from "react";

class Footer extends React.Component {
    render() {
        return (
        <div class="border-t-2 border-gray border-opacity-10 ml-8 mr-8 lg:mx-48 lg:my-0">
            <div class=" lg:grid lg:gap-4 lg:grid-cols-3 py-8">
                <div class="hidden lg:block font-body text-xs lg:text-sm text-gray text-center lg:text-left"><a href="http://www.look-werbeservice.de/">Homepage</a></div>
                <div class="font-body text-xs mb-2 lg:text-sm text-gray-middle text-center ">© 2021 LOOK! Werbeservice GmbH. All rights reserved.</div>
                <div class="font-body text-xs lg:text-sm text-gray text-center lg:text-right "><a href="http://www.look-werbeservice.de/impressum/">Impressum</a></div>
            </div>
        </div>
        );
    };
}

export default Footer;