import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import ReactPlayer from "react-player";

export default function ViewProdir() {
  let [isOpen, setIsOpen] = useState(false)

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  return (
    <>
      <div className="">
        <button
          type="button"
          onClick={openModal}
          className="btn-red"
        >
          Play Video
        </button>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          class="fixed inset-0 z-10 bg-white bg-opacity-90"
          onClose={closeModal}
        >
          <div className="text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block overflow-hidden align-middle transition-all transform bg-transparent">
                <div class="relative h-96 w-screen  lg:w-screen lg:h-96 ">
                <div class="absolute w-screen h-full">
                    <ReactPlayer 
                    url='https://www.youtube-nocookie.com/embed/FAoC9NTfKxQ?rel=0' 
                    controls='false'
                    height="100%"
                    width="100%"
                    
                    playing='true'
                    />
                </div>
                </div>

                <div class=" mt-2 ">
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-1 mb-2 text-sm font-medium text-white bg-red border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={closeModal}
                  >
                    schließen
                  </button>
                </div>
                
              </div>

            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}


