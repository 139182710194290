import React from "react";
import { ChartBarIcon } from "@heroicons/react/solid"

class FullService extends React.Component {
    render() {
        return (
        <div class="bg-gray-light rounded-md p-5 lg:p-10 h-22 content-center">
            <ChartBarIcon class="h-10 text-red lg:h-16" />
            <p class="text-2xl text-gray font-medium mt-5 lg:mt-6" >Full-Service</p>
            <p class="text-sm lg:text-base text-gray text-justify mt-2" >Bestelle deine haptischen Werbeträger auf Abruf ohne eigenen Lageraufwand direkt online.</p>
            
        </div>
        );
    };
}

export default FullService;