import Header from "./components/Header";
import Teaser from "./views/Teaser";
import Portfolio from "./views/Portfolio";
import GetInTouch from "./components/GetInTouch";
import Footer from "./components/Footer";
import ViewProdir from "./views/ViewProdir";
import ViewWeBook from "./views/ViewWeBook";
import ViewUma from './views/ViewUma';
import ViewRastal from './views/ViewRastal';
import ViewMahlwerck from './views/ViewMahlwerck';
import ViewKoziol from './views/ViewKoziol';

function App() {
  return (
    <div class="h-screen bg-gray-light">
      <Header />
      <Teaser />
      <ViewProdir />
      <ViewWeBook />
      <ViewRastal />
      <ViewUma />
      <ViewMahlwerck />
      <ViewKoziol />
      <Portfolio />
      <GetInTouch />
      
      <Footer /> 
    </div>
  );
}

export default App;
