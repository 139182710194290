import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { PhoneIcon, MailIcon, OfficeBuildingIcon, ArrowNarrowLeftIcon } from '@heroicons/react/solid'


export default function ViewContact() {
  const [open, setOpen] = useState(false)

  
  function openModal() {
    setOpen(true)
  }

  return (
    <>
    <div className="">
        <button
          type="button"
          onClick={openModal}
          className="btn-red"
        >
          Kontakt
        </button>
      </div>

    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" static className="fixed inset-0 overflow-hidden" open={open} onClose={setOpen}>
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-white bg-opacity-90 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="relative w-screen max-w-md">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-500"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-500"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4">
                    <button
                      className="rounded-md text-gray  hover:text-red focus:outline-none focus:ring-2 focus:ring-white"
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">Close panel</span>
                      <ArrowNarrowLeftIcon className="h-8 w-8" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                  <div className="px-4 sm:px-6">
                    <Dialog.Title className="text-2xl font-display text-red text-center">Wir freuen uns auf deine Anfrage!</Dialog.Title>
                  </div>
                  <div className="mt-6 relative flex-1 px-4 sm:px-6">

                    {/* Content */}
                    <div className="absolute inset-0 px-4 sm:px-6">
                      <div className="h-full grid grid-cols-1 place-content-center place-items-center" aria-hidden="true">
                        
                          <PhoneIcon class="h-8 text-red mb-4" />
                          <p class="font-body text-gray text-xl mb-24">+49 (0)40 - 300 67 00</p>
                        
                        
                          <MailIcon class="h-8 text-red mb-4" />
                          <p class="font-body text-center text-gray text-xl mb-24">anfrage@look-werbeservice.de</p>

                          <OfficeBuildingIcon class="h-8 text-red mb-4" />
                          <p class="font-body text-gray text-xl">Bullerdeich 14</p>
                          <p class="font-body text-gray text-xl">Kraftwerk Bille - Bauteil 6</p>
                          <p class="font-body text-gray text-xl">20537 Hamburg</p>
                        
                      </div>
                    </div>
                    {/* /End */}

                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    </>
  )
}

