import React from "react";
import ViewContact from "../views/ViewContactWhite";
import logo from "../img/logo.png"

class Header extends React.Component {
    render() {
        return (
            <header class="fixed flex w-full bg-white shadow pl-8 pr-8 pt-6 pb-6 lg:pl-48 lg:pr-48  ">
                <div class="flex-none h-full">
                    <img src={logo} alt="Logo" class="h-10"  />
                </div>
                <div class="flex-grow h-full">

                </div>
                <div class="flex-none h-full place-self-center">
                    <ViewContact />    
                </div>
                
            </header>
        );
    };
}

export default Header;
