import React from "react";
import { GiftIcon } from "@heroicons/react/solid";

class ServiceWerbemittel extends React.Component {
    render() {
        return (
        <div class="bg-gray-light rounded-md p-5 lg:p-10 content-center">
            <GiftIcon class="text-red h-10 lg:h-16" />
            <p class="text-2xl text-gray font-medium mt-5 lg:mt-6" >Werbemittel</p>
            <p class="text-sm lg:text-base  text-gray text-justify mt-2" >Langlebige und wirkungsvolle haptische Werbeträger für deine Marketingkampagne.</p>
            
        </div>
        );
    };
}

export default ServiceWerbemittel;