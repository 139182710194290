import React from "react";
import FullService from "../components/ServiceFullService";
import ServiceWerbemittel from "../components/ServiceWerbemittel";
import Sonderproduktion from "../components/Sonderproduktion";

class Portfolio extends React.Component {
    render() {
        return (
        <div class="mx-8 my-7 lg:mx-48 ">
            <p class="text-3xl lg:text-5xl font-display text-red  mb-8 tracking-wide text-center">Portfolio</p>
            <div class="lg:grid gap-y-4 lg:gap-x-12 grid-cols-1 lg:grid-cols-3 ">
                <div class="mb-5"><ServiceWerbemittel /></div>
                <div class="mb-5"><FullService /></div>
                <div><Sonderproduktion  /></div>
            </div>
        </div>
        );
    };
}

export default Portfolio;