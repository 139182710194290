import React from "react";
import ViewContactRed from "../views/ViewContactRed"

class GetInTouch extends React.Component {
    render() {
        return (
        <div class="grid bg-red shadow-md lg:rounded-md content-center px-8 lg:px-0 py-10 lg:py-14 mx-0 my-10 lg:mx-48 lg:my-16 ">
            <p class="font-display font-bold text-white text-xl lg:text-4xl text-center mb-4 lg:mb-6">Haben wir dein Interesse geweckt?</p>
            <p class="font-body text-white text-sm lg:text-base text-center mb-6 lg:mb-12">Gerne beraten wir dich persönlich um die perfekten haptischen Werbeträger passend zu deinem Marketingkonzept zu finden!</p>
            <div class="justify-self-center">
            <ViewContactRed />
            </div>
        </div>
        );
    };
}

export default GetInTouch;