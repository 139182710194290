import React from "react";
import KoziolPicture from "../img/KoziolPicture.jpg";

class ViewKoziol extends React.Component {
    render() {
        return (
        <div class="h-auto grid place-content-center mx-8 my-24 lg:mx-72 lg:my-60">

            <div class="mb-12">  
                <p class=" font-display font-semibold text-3xl text-red  mb-8 tracking-wide text-center lg:text-6xl ">Koziol Smart Products</p>
                <p class="font-body text-sm text-center text-black lg:text-xl lg:mb-12 ">Verbinde deine Marketingkampange mit den hochwertigen Produkten von Koziol</p>
            </div>  
            <div class="lg:flex lg:justify-center">
                    <img src={KoziolPicture} alt="webook_productpicture" class="mb-8 lg: w-auto" />
                </div>
            

        </div>
        );
    };
}

export default ViewKoziol;

