import React from "react";
import UmaPicture from "../img/UmaPicture.jpg";

class ViewUma extends React.Component {
    render() {
        return (
        <div class="h-auto grid place-content-center mx-8 my-24 lg:mx-72 lg:my-60">

            <div class="mb-12">  
                <p class=" font-display font-semibold text-3xl text-red  mb-8 tracking-wide text-center lg:text-6xl ">uma NFC Pen</p>
                <p class="font-body text-sm text-center text-black lg:text-xl lg:mb-12 ">Verbinde das analoge Schreiben und Werben mittels NFC-Technik</p>
            </div>  

            <div class="justify-center lg:grid lg:gap-0 lg:grid-cols-2 ">

                <div class="lg:flex lg:justify-center">
                    <img src={UmaPicture} alt="webook_productpicture" class="mb-8 lg:h-96 w-auto" />
                </div>

                <div class=" lg:grid lg:grid-cols-1 lg:place-content-start">

                    <div>
                    <p class="text-justify font-body leading-relaxed lg:leading-relaxed text-sm lg:text-base text-gray mb-4 lg:mb-8">
                        Gestalte deine CI-konforme elektronische BusinessCard und übermittel deine Daten schnell und unkompliziert mit dem uma® "inside NFC PEN".
                        <br/><br/>
                        Mit dem NFC BusinessCard-Service erhältst du Zugang zu deiner webbasierten Datenpflege deiner BusinessCard-Kontaktdaten und kannst diese ganz individuell gestalten. 
                    </p>
                       
                    </div>

                </div>

            </div>

        </div>
        );
    };
}

export default ViewUma;

