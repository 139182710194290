import React from "react";
import ProdirPicture from "../img/ProdirPicture.jpg";
import VideoProdir from "../video/VideoProdir";

class ViewProdir extends React.Component {
    render() {
        return (
        <div class="h-auto grid place-content-center mx-8 my-24 lg:mx-72 lg:my-60">

            <div class="mb-12 ">  
                <p class=" font-display font-semibold text-3xl text-red  mb-8 tracking-wide text-center lg:text-6xl ">Prodir Cloud Pens</p>
                <p class="font-body text-sm text-center text-black lg:text-xl lg:mb-12 ">Der PRODIR Cloud Pen mit QR Code für deine Werbekampagne mit messbarer Erfolgsanalyse deiner Konversionsraten per Webportal</p>
            </div>  

            <div class="justify-center lg:grid lg:gap-0 lg:grid-cols-2 ">

                <div class="lg:flex lg:justify-center">
                    <img src={ProdirPicture} alt="webook_productpicture" class="mb-8 lg:h-96 w-auto" />
                </div>

                <div class=" lg:grid lg:grid-cols-1 lg:place-content-start">

                    <div>
                    <p class="text-justify font-body leading-relaxed lg:leading-relaxed text-sm lg:text-base text-gray mb-4 lg:mb-8">
                        Lasse die analoge Botschaft deiner Schreibgeräte direkt in deine digitale Kommunikation einfließen.<br/><br/>
                        Über das benutzerfreundliche Admin Dashboard hältst du deine Kommunikation schnell und einfach auf dem neuesten Stand und dass über die gesamte Lebensdauer deines auf Langlebigkeit ausgelegten Markenbotschafters.<br/><br/>
                        Deine Landingpage lässt sich personalisieren und nahtlos in deinen Markenauftritt einbauen. Von Social-Media Kanälen bis hin zur Seiten-URL kann praktisch jedes Detail angepasst werden. Und da die Webseite in der Schweiz gehostet wird - dem Land, das laut Data Centre Risk Index zu den drei weltweit führenden Ländern in puncto Sicherheit zählt, kannst du dich darauf verlassen, dass deine Daten sicher sind und gemäß Datenschutzgrundverordnung (DSGVO) behandelt werden.
                    </p>
                        <div class="flex justify-center lg:flex-none lg:justify-start">
                        <VideoProdir />
                        </div>
                    </div>

                </div>

            </div>

        </div>
        );
    };
}

export default ViewProdir;

