import React from "react";
import RastalPicture from "../img/RastalPicture.jpg";

class ViewRastal extends React.Component {
    render() {
        return (
        <div class="h-auto grid place-content-center mx-8 my-24 lg:mx-72 lg:my-60">

            <div class="mb-12">  
                <p class=" font-display font-semibold text-3xl text-red  mb-8 tracking-wide text-center lg:text-6xl ">Rastal Smartglass®</p>
                <p class="font-body text-sm text-center text-black lg:text-xl lg:mb-12 ">Schaffe neue Ansätze in der Kundenansprache</p>
            </div>  

            <div class="justify-center lg:grid lg:gap-0 lg:grid-cols-2 ">

                <div class="lg:flex lg:justify-center">
                    <img src={RastalPicture} alt="webook_productpicture" class="mb-8 lg:h-96 w-auto" />
                </div>

                <div class=" lg:grid lg:grid-cols-1 lg:place-content-start">

   
                <p class="text-justify font-body leading-relaxed lg:leading-relaxed text-sm lg:text-base text-gray mb-4 lg:mb-8">
                Das Rastal Smartglass® ist eine Kombination aus Trinkglas und Transponder mit NFC-Technologie.
                <br/><br/>
                Das neuartige Druckverfahren Rastal Smartprint® verbindet den NFC-Transponder elegant und spülmaschinenfest mit deiner Markenbotschaft. Diese smarte Veredelung schafft neue Ansätze in der Kundenansprache, Kundeninteraktion, Wettbewerbsdifferenzierung und Konsumförderung.
                </p>
                      
            

                </div>

            </div>

        </div>
        );
    };
}

export default ViewRastal;

